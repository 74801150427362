// import React, { useState } from "react";
import styled from "styled-components";
// Components
import prosespembuatan from "../../assets/img/prosespembuatan.png";

// const ReadMore = ({ children }) => {
//   const text = children;
//   const [isReadMore, setIsReadMore] = useState(true);
//   const toggleReadMore = () => {
//     setIsReadMore(!isReadMore);
//   };
//   return (
//     <p className="font15" style={{ lineHeight: 1.5, fontFamily: "Cabin" }}>
//       {isReadMore ? text.slice(0, 970) : text}
//       <span
//         onClick={toggleReadMore}
//         // className="read-or-hide"
//         style={{ color: "blue", cursor: "pointer" }}
//       >
//         {isReadMore ? "...read more" : " show less"}
//       </span>
//     </p>
//   );
// };

export const Content = () => {
  return (
    <>
    <div className="font20 fontCabin lh30 mr25">
      {/* <h2> */}
      {/* <ReadMore> */}
        Originally Shibori comes from Japan, deriffed from the word Shiboru (shape-resist dyeing / Tie Dye), which means the technique of holding fabric by tying, pressing, folding, compressing very tightly and then dyeing with coloring material.

        {/* Shibori techniques vary widely from simple folded tie techniques to more difficult techniques called Nui Shibori or Stitch Shibori which use a needle and thread. Apart from Japan, India and Africa, it turns out that Indonesia also has Indonesian Wastra works such as Pelangi or Cinde in Palembang, Tritik or Jumputan in Java, and Sasirangan in Banjarmasin, in which the technique of making the motifs they use is also included in the shibori technique, since the shibori itself has more than 500 techniques. */}

        {/* Indonesia has various Batik literature across the nation, from Sabang to Merauke. From there, our desire to create Indonesian Batik motifs using the shibori technique arose. Further more this background makes us dig deeper into making shibori cloth and processing it into derivative products, such as clothing, shawls, selendang, home living products such as tablecloths, bed runners, pillowcases, totebags, hats, bags, accessories and even wall decorations or shibori painting.

        The techniques that most often applied currently in Rosita Batik Shibori are Makiage, Makinui, Mokume, Orinui, Karamatsu and Guntai techniques. These techniques can be applied simultaneously. */}

      {/* </ReadMore> */}
      {/* </h2> */}
    </div>
    <div className="font20 fontCabin lh30 mr25 mt20">
    Shibori techniques vary widely from simple folded tie techniques to more difficult techniques called Nui Shibori or Stitch Shibori which use a needle and thread.
    </div>
    </>
  );
};

export default function Services() {
  // const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Wrapper id="services">
      {/* <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div> */}
      <div
        className="whiteBg"
      // style={{ padding: "60px 0" }}
      >
        {/* <div className="container"> */}
        {/* <HeaderInfo>
            <h1 className="font40 extraBold">Story About Batik Shibori</h1>
            <p className="font13">
              Rosita Batik Shibori is genuinely born through stories of life
              that define our existence. The language she uses spoken by the silhouette,
              <br />
               the material and
              the colour she experienced. Every silhouette is the translation of
              every story,
              <br />
              every fabric has their own time, and every colour sings the song
              of a persona.
            </p>
          </HeaderInfo> */}
        {/* <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title="Fabric"
                subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="Colour"
                subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Manufacture"
                subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="printer" title="Print" subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor." />
            </ServiceBoxWrapper>
          </ServiceBoxRow> */}
        {/* </div> */}
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h1
                  className="font40 extraBold"
                  style={{
                    fontFamily: "Roboto",
                    paddingBottom: 12,
                    paddingTop: 12,
                  }}
                >
                  A Story of Creativity
                </h1>
                {/* <p
                  className="font15"
                  style={{
                    lineHeight: 1.5,
                    fontFamily: "Cabin",
                  }}
                > */}
                <Content />

                {/* Indonesia memiliki beragam motif batik yang beraneka ragam,
                  kami memulai menggabungkan motif batik Indonesia dengan
                  gabungan teknik Shibori Melihat dari sejarahnya, shibori
                  berasal dari nagara Jepang, dari kata Shiboru atau nama
                  lainnya shape-resist dyeing / Tie Dye / ikat celup, yang
                  artinya adalah Teknik merintangi kain dengan cara mengikat,
                  menekan, melipat, mengkonpres dengan sangat erat dan dilakukan
                  pencelupan dengan pewarna. Dari Teknik ikat lipat yang
                  sederhana sampai Teknik yang lebih sulit yang disebut Nui
                  Shibori atau Stitch Shibori yang menggunakan jarum dan benang.
                  <br />
                  Selain di negara Jepang, India sampai Afrika, ternyata
                  Indonesia juga memiliki karya Wastra Nusantara seperti Pelangi
                  atau Cinde dari Palembang, Tritik atau Jumputan dari Jawa,
                  serta Sasirangan dari Banjarmasin merupakan bagian dari lebih
                  dari 500 teknik dalam shibori. <br />
                  Dari latarbelakang tersebut saya sangat berminat menggeluti
                  pembuatan kain shibori dan mengolahnya menjadi produk
                  turunannya yaitu pakaian, selendang, syal, produk home living
                  seperti taplak meja, bed runner, sarung bantal, apron set,
                  selain itu juga tottebag, topi, tas sampai hiasan dinding atau
                  lukisan shibori. Bermula dari Teknik yang sederhana seperti
                  teknik ikat lipat seperti kumo, miura, arashi, itajime,
                  kemudian saya meningkatkan kemampuan saya untuk Teknik yang
                  lebih sulit yaitu Nui Shibori atau Stitch Shibori yang saya
                  pelajari dari buku buku asing, e-book, youtube. Dan yang
                  paling banyak saya pergunakan dalam produk produk saya saat
                  ini adalah Teknik Makiage, Makinui, Mokume, orinui, Guntai,
                  Karamatsu, Guntai. Karena Indonesia memiliki motif batik yang
                  beranekaragam, saya mulai menggabungkan motif Batik Indonesia
                  dan mengerjakannya dengan gabungan beberapa Teknik shibori,
                  seperti batik Kawung, Batik Parang, batik bermotif flora dan
                  fauna. Sehingga motif motif Shibori yang saya buat memiliki
                  ciri khas yang bahkan di negara Jepang sendiri tidak di
                  temukan. */}
                {/* Berdiri sejak tahun 2017. Berawal dari ketertarikan kepada
                  Teknik ikat lipat shibori yang menghasilkan motif pada kain,
                  yang uniknya dari setiap ikatan walaupun dilakukan dengan cara
                  yang sama namun akan menghasilkan motif yang berbeda / tidak
                  pernah akan persis sama. Mengapa demikian, karena Shibori
                  adalah produk <i>Handmade</i>. <br /> dari kain terciptalah
                  pakaian, produk home living dan yang terbaru saya
                  mengembangkan produk lukisan shibori. <br /> <br />
                  Karena Indonesia memiliki motif batik yang beranekaragam, kami
                  mulai menggabungkan motif Batik Indonesia dan mengerjakannya
                  dengan gabungan beberapa Teknik shibori, seperti batik Kawung,
                  Batik Parang, batik bermotif flora dan fauna. <br /> Sehingga
                  motif motif Shibori dari Rosita batik shibori memiliki ciri
                  khas yang bahkan di negara Jepang sendiri tidak di temukan. */}
                {/* </p> */}
                {/* <ButtonsRow
                  className="flexNullCenter"
                  style={{ margin: "30px 0" }}
                >
                  <div style={{ width: "190px" }}>
                    <FullButton
                      title="Show More"
                      action={() => alert("clicked")}
                    />
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton
                      title="Contact Us"
                      action={() => alert("clicked")}
                      border
                    />
                  </div>
                </ButtonsRow> */}
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="">
                    <AddImgWrapp1>
                      <img src={prosespembuatan} alt="office" />
                    </AddImgWrapp1>
                    {/* <AddImgWrapp2>
                      <img src={about5} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={about4} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={about3} alt="office" />
                    </AddImgWrapp4> */}
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: 10%;
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -30px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
// const AddImgWrapp1 = styled.div`
//   width: 48%;
//   margin: 0 6% 10px 6%;
//   img {
//     width: 100%;
//     height: auto;
//     border-radius: 1rem;
//     box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//     -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
//   }
// `;

const AddImgWrapp1 = styled.div`
  width: 100%;
  margin-top: 0;
  // margin: 0 6% 10px 6%;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: auto;
    // border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
