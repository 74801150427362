import React, { useCallback, useEffect, useMemo, useState } from "react";
import Footer from "../components/Sections/Footer";
import { useParams } from "react-router-dom";
import "../style/detailnews.css";
import Navigations from "../components/Nav/navigations";
import { numberWithCommas } from "../redux/actions/product";

export default function DetailProduct() {
  const { category, id } = useParams();
  const [detail, setdetail] = useState("");


  const TCategory = {
    product: 'product',
    product_men: 'productMen',
    product_accessories: 'productAccessories'
  }

  const getContentNewsDetail = useCallback(() => {
    fetch(`https://api.rositabatikshibori.com/api/v1/${category}/${id}`, {
      method: "GET",
      headers: {
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJ1c2VybmFtZSI6ImFkbWluIiwiaWF0IjoxNzE3NDI4MzI3fQ.brC0Z-uiBEek-dYIpIDjluujyUHWPWxPywqpd_FoaBw",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setdetail(data.data.data);
      })
      .catch((error) => {
        throw error
      });
  }, [id, category]);

  useEffect(() => {
    getContentNewsDetail();
  }, [getContentNewsDetail]);

  const isProductSize = useMemo(() => {
    return ['product_men', 'product'].includes(category)
  }, [category]);

  const getProductSize = useMemo(() => {
    if (isProductSize && detail?.product_size) {
      const parseData = JSON.parse(detail?.product_size);
      return parseData;
    }
  }, [isProductSize, detail?.product_size]);

  return (
    <>
      <Navigations />
      <div className="container mt100 mb25">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 flexCenter">
            <img
              style={{ objectFit: "cover", }}
              width={'80%'}
              height={'auto'}
              alt="return"
              src={
                `https://api.rositabatikshibori.com/asset/${TCategory[category]}/` +
                detail.product_photo
              }
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7">
            <div style={{ maxWidth: "100%", }}>
              <p className="font25 fontRobotto mt25 mb20 semiBold">{detail.product_name}</p>
              {detail?.product_description !== 'undefined' && (
                <p className="fontCabin font18">{detail.product_description}</p>
              )}
              <p className="semiBold font18 mt25 fontRobotto mb10">Type :</p>
              <p className="fontCabin font18">{detail.product_type}</p>
              {isProductSize && getProductSize?.length > 0 && (
                <>
                  <p className="semiBold font18 mt25 fontRobotto mb10">Size :</p>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                    {getProductSize.map((item, index) => (
                      <p key={index} className="p5 fontCabin" style={{border: '1px solid #27456F',}}>{item}</p>
                    ))}
                  </div>
                </>
              )}
              {detail?.product_price !== 'undefined' && (
                <p className="semiBold fontCabin font20 mt25 mb25">Rp {numberWithCommas(detail.product_price)}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
