import React from "react";
import { Helmet } from "react-helmet";
// Screens
import { Route, Routes } from "react-router-dom";
import routes from "./routes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "../src/redux/store";

export default function App() {
  const { store, persistor } = configureStore();

  return (
    <>
      <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <Routes>
        {routes.map(({ path, element }) => (
          <Route key={path} exact path={path} element={element} />
        ))
        }
      </Routes>
      </PersistGate>
    </Provider>
    </>
  );
}

