import React from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
// Assets
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaWhatsapp,
} from "react-icons/fa6";
import bumibiruhputih from "../../assets/img/bumibiruputih.png";
import Logorositaputih from "../../assets/img/logorositaputih.png";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 500,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  // boxShadow: 24,
  p: 4,
};

const listPartnership = [
  {
    key: `\u2022`,
    value: [
      'Shibori Class for Kid and Adult',
      '(Beginner until Advance)'
    ]
  },
  {
    key: `\u2022`,
    value: [
      'Fashion Show',
    ]
  },
  {
    key: `\u2022`,
    value: [
      'Special Collaboration'
    ]
  }
]

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Wrapper id="footer">
      <div className="darkBg">
        <div className="container">
          <div
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div style={{ lineHeight: 1.5 }}>
              <div
                style={{
                  color: "white",
                  padding: "30px 0",
                  fontWeight: "bold",
                  fontFamily: "Cabin",
                }}
              >
                Discovering us
              </div>
              <div style={{ color: "white", fontFamily: "Cabin" }}>
                Northwest boulevard NV 1 NO 55{" "}
              </div>
              <div style={{ color: "white", fontFamily: "Cabin" }}>
                Monday-Friday 09.00-17.00{" "}
              </div>
              <div style={{ color: "white", fontFamily: "Cabin" }}>
                Saturday 09.00-15.00
              </div>
              <div style={{ color: "white", fontFamily: "Cabin" }}>
                +62 821-2503-5550
              </div>
            </div>

            <div>
              <div
                style={{
                  color: "white",
                  padding: "30px 0",
                  fontWeight: "bold",
                  lineHeight: "1.5rem",
                  fontFamily: "Cabin",
                }}
              >
                Shopping Guide
              </div>
              <div style={{ lineHeight: 1.5 }}>
                <div>
                  <a href="/FAQ" className="pointer" style={{ color: "white", fontFamily: "Cabin" }}>FAQ</a>
                </div>
                <div>
                  <a href="/ReturnRepair" className="pointer" style={{ color: "white", fontFamily: "Cabin" }}>Return or Repair</a>
                </div>
                {/* <div style={{ color: "white", fontFamily: "Cabin" }}>
                  Custom
                </div>
                <div style={{ color: "white", fontFamily: "Cabin" }}>
                  Payment
                </div> */}
                <div
                  onClick={handleOpen}
                  className=""
                >
                  <div className="fontWhite fontCabin pointer">
                    Partnership
                  </div>
                </div>
              </div>
            </div>
            <div>
              <a
                href="https://wa.me/6282125035550"
                className=""
              >
                <div
                  style={{
                    color: "white",
                    padding: "30px 0",
                    fontWeight: "bold",
                    fontFamily: "Cabin",
                    cursor: "pointer"
                  }}
                >
                  Let's Collaboration
                </div>
              </a>

              <ul>
                <li
                  className="semiBold font15 pointer flexCenter"
                  style={{ fontFamily: "Cabin" }}
                >
                  <a
                    href="https://www.instagram.com/rositabatikshibori.official?igsh=MTRqZTNlYTEyeGdzNA=="
                    className=""
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 10,
                    }}
                  >
                    <FaInstagram color="white" size={20} />
                  </a>
                  <a
                    href="https://web.facebook.com/profile.php?id=100066575509913"
                    className=""
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 10,
                    }}
                  >
                    <FaFacebookF color="white" size={18} />
                  </a>

                  <a
                    href="https://www.tiktok.com/@rositabatikshibori?_t=8nSsZjZSWo8&_r=1"
                    className=""
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 10,
                    }}
                  >
                    <FaTiktok color="white" size={20} />
                  </a>
                  <a
                    href="https://wa.me/6282125035550"
                    className=""
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 10,
                    }}
                  >
                    <FaWhatsapp color="white" size={20} />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            <Link
              className="flexCenter animate pointer"
              to="home"
              smooth={true}
              offset={-80}
            >
              {/* <LogoImg />
              <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
                Fanatic
              </h1> */}
              <img
                style={{ marginRight: "20px" }}
                src={Logorositaputih}
                alt="logobatikshibori"
                className="radius6"
                width={100}
              />
              <img
                src={bumibiruhputih}
                alt="logobatikshibori"
                className="radius6"
                width={100}
              />
            </Link>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} -{" "}
              <span
                className="purpleColor font13"
                style={{ fontFamily: "Cabin" }}
              >
                Rosita Batik Shibori
              </span>{" "}
              All Right Reserved
            </StyleP>

            <a
              className="whiteColor animate pointer font13"
              href="/"
              smooth={true}
              offset={-80}
              style={{ fontFamily: "Cabin" }}
            >
              Back to Home
            </a>
          </InnerWrapper>
        </div>
        <div>
          {/* <Button onClick={handleOpen}>Open modal</Button> */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="justifyCenter">
                <p className="semiBold fontRobotto font20 textCenter mb20">Let's Build Happiness With Being Our Partner</p>
                {listPartnership.map((item) => (
                  <div key={item?.key} className="flex">
                    <p className="fontCabin font18 mt10">{item?.key}</p>
                    <div>
                      {item?.value.map((val) => <p className="ml20 fontCabin font18 mt10">{val}</p>)}
                    </div>
                  </div>
                ))}
                {/* <div className="flex justifyCenter">
                <Button variant="contained" href="https://wa.me/6282125035550">
                  CONTACT US
                </Button>
                </div> */}

                <div className="mt25 mb20 darkBg radius6 flex justifyCenter itemsCenter flexCenter">
                    <MuiLink
                      href="https://wa.me/6282125035550"
                      style={{lineHeight: 3, color: '#fff', fontFamily: 'cabin'}}
                    >
                      CONTACT US
                    </MuiLink>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
